import splide, { customSplide } from "../utils/splide";

function initAnimaNumeros() {
  function animaNumeros() {
    const numeros = document.querySelectorAll("counter");
    const duracaoAnimacao = 2500;
    const frames = duracaoAnimacao / 25;

    numeros.forEach((numero) => {
      const total = +numero.dataset.numero;
      const incremento = total / frames;
      let start = 0;

      const timer = setInterval(() => {
        start = Math.min(start + incremento, total);
        numero.innerText = Math.floor(start);
        if (start >= total) {
          numero.innerText = total;
          clearInterval(timer);
        }
      }, 25);
    });
  }

  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animaNumeros();
          observer.unobserve(entry.target);
        }
      });
    },
    { threshold: 0.5 }
  );

  const observerTarget = document.querySelector(".metrics");
  observer.observe(observerTarget);
}

document.addEventListener("DOMContentLoaded", function () {
  initAnimaNumeros();
});

const banner = document.getElementById("banner");
if (banner) {
  const bannerSplide = customSplide(banner, {
    type: "fade",
    rewind: true,
    perPage: 1,
    autoplay: true,
    speed: 2000,
    lazyLoad: "sequential",
    pagination: false,
    arrows: banner.dataset.slides > 1 ? true : false,
    breakpoints: {
      1200: {
        pagination: true,
        arrows: false,
      },
      600: {
        fixedHeight: "unset",
        pagination: true,
        arrows: false,
      }
    },
  });
  bannerSplide.mount();
}

const diferentials = document.getElementById("diferentials")
if (diferentials) {
  const diferentialsSplide = splide(diferentials, {
    perPage: 4,
    pagination: false,
    arrows: false,
    breakpoints: {
      768: {
        perPage: 2,
        pagination: false,
        arrows: false,
        autoplay: true,
      },
      475: {
        perPage: 1,
        pagination: false,
        arrows: false,
        autoplay: true,
      },
    }
  })
  diferentialsSplide.mount()
}

const lines = document.getElementById("lines")
if (lines) {
  const linesSplide = splide(lines, {
    autoplay: false,
    perPage: 4,
    arrows: true,
    breakpoints: {
      768: {
        perPage: 2,
        arrows: false,
        pagination: true,
      },
      475: {
        perPage: 2,
        arrows: false,
        pagination: true,
      },
    }
  })
  linesSplide.mount()
}

const news = document.getElementById("news");
if (news) {
  const newsSplide = splide(news, {
    perPage: 3,
    breakpoints: {
      1200: {
        perPage: 2,
        arrows: false,
        pagination: true,
      },
      600: {
        perPage: 1,
        pagination: true,
      },
    },
  });
  newsSplide.mount();
}

const certification = document.getElementById("certifications");
if (certification) {
  const certificationSplide = splide(certification, {
    perPage: 4,
    breakpoints: {
      768: {
        perPage: 3,
        arrows: false,
        pagination: true,
      },
      475: {
        perPage: 1,
        arrows: true,
      },
    },
  });
  certificationSplide.mount();
}
